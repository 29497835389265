<template>
  <div>
    <template v-if="dreamer">
      <custom-breadcrumb
        :pageTitle="$t('forms.edit_model', {model:$t('models.dreamer')})"
        :breadcrumb="breadcrumb"
      ></custom-breadcrumb>
      <b-row>
        <b-col>
          <b-card>
            <b-tabs v-model="tabIndex" v-if="dreamer">
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="InfoIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('models.dreamer')}}</span>
                </template>
                <tab-main
                  :school="school"
                  :dreamer="dreamer"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>
              
              <!-- TAB: Ajustes NEE/ PIN -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="SettingsIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('customize.settings')}}</span>
                </template>
                <tab-settings
                  :dreamer="dreamer"
                  :nee="nee"
                  :topics="topics"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- Users -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('fields.student_account')}}</span>
                </template>
                <tab-users
                  :school="school"
                  :dreamer="dreamer"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- Groups -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="SmileIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('Groups')}}</span>
                </template>
                <tab-groups
                  :school="school"
                  :dreamer="dreamer"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

               <!-- Customización -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="SlidersIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('customize.customization')}}</span>
                </template>
                <customization-component
                  :dreamers="[dreamer.id]"
                  :isGroup="false"
                  :dataChanged="dataChanged"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- CONTENT RESTRICTION -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="ClockIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('customize.mandatory')}}</span>
                </template>
                <content-restriction-component
                  :info="[dreamer]"
                  :dreamers="[dreamer.id]"
                  :isGroup="false"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>
            </b-tabs>
          </b-card>
          <b-button
            variant="outline-secondary"
            type="button"
            @click="hasHistory() 
              ? $router.go(-1) 
              : $router.push({ 
                name: 'school-dreamers-view', 
                params: { 
                  id: school.id,
                  dreamer_id: dreamer.id, 
                } 
              })"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            {{ $t('forms.go_back') }}
          </b-button>

        </b-col>
      </b-row>

    </template>
    <template v-else>
      <b-alert
        variant="danger"
        :show="dreamer === undefined"
      >
        <h4 class="alert-heading">
          Error fetching dreamer data
        </h4>
        <div class="alert-body">
          No dreamer found with this id. Go back to
          <b-link
            class="alert-link"
            :to="{ name: 'schools'}"
          >
            Schools List
          </b-link>
        </div>
      </b-alert>
    </template>
  </div>
</template>

<script>
import { fetchSchoolDreamer } from '@/api/routes'
import router from '@/router'
import TabMain from './edit/TabMain.vue'
import TabUsers from './edit/TabUsers.vue'
import TabGroups from './edit/TabGroups.vue'
import TabSettings from './edit/TabSettings.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import {
  BTab, BTabs, BCard, BAlert, BLink,
  BRow, BCol, BButton,
} from 'bootstrap-vue'

import CustomizationComponent from '@/views/sl-components/CustomizationComponent.vue'
import ContentRestrictionComponent from '@/views/sl-components/ContentRestrictionComponent.vue'


export default {
  components:{
    CustomizationComponent,
    ContentRestrictionComponent,

    CustomBreadcrumb,
    TabGroups,
    TabUsers,
    TabMain,
    TabSettings,
    BTab, BTabs, BCard, BAlert, BLink,BRow, BCol, BButton

  },
  data(){
    return {
      school: {},
      dreamer:null,
      tabIndex:null,
      nee: [],
      topics: [],
      dataChanged: 0
    }
  },
  computed:{
    breadcrumb(){
          if(this.$store.getters['user/isSchool']){
            return [
              {
                text: this.$t('Schools'),
              },
              {
                text: this.school.name
              },
              {
                to: 'school-dreamers-list',
                text: this.$t('List_model', {model: this.$t('models.dreamers')})
              },
              {
                text: this.$t('Edit'),
                active: true,
              },
            ]  
          } else{
            return [
              {
                text: this.$t('Schools'),
                to: 'schools'
              },
              {
                to: 'school-view',
                params: {
                  id: this.school.id,
                },
                text: this.school.name
              },
              {
                to: 'school-dreamers-list',
                text: this.$t('List_model', {model: this.$t('models.dreamers')})
              },
              {
                text: this.$t('Edit'),
                active: true,
              },
            ]            
          }
    }
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    async getData(id){
        let data = {
          'id': id,
          'school_id': this.$route.params.id,
        }
        await this.$http.post(fetchSchoolDreamer, data).then( response =>{
          // console.log(response)
          if(response.data.status == 200) {
            this.school = response.data.school;
            this.dreamer = response.data.dreamer;
            this.nee = response.data.nee;
            this.topics = response.data.topics;
            this.dataChanged = this.dataChanged +1
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
  mounted(){
    this.id = router.currentRoute.params.dreamer_id;
    this.getData(this.id)
    // console.log(router.currentRoute.params.tab)
    this.tabIndex = router.currentRoute.params.tab || 0;
  },
}
</script>

<style>

</style>