<template>
  <div class="avatar-modal mb-2">
    <b-row style="text-align:right;">
      <b-col>
        <b-button
          variant="gradient-danger"
          class="btn-icon rounded-circle"
          @click="$emit('toggle-edit-avatar')"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols='12'>

        <div class="avatar-modes-item" @click="currentAvatarGroup = 'small'">
          <img :src="require('@/assets/images/avatar/peq_girl.png')"
            :class="[currentAvatarGroup == 'small' ? 'current_avatar' : 'avatar']">
         <p> {{$t('Small')}}</p>
        </div>
        <div class="avatar-modes-item" @click="currentAvatarGroup = 'big'">
          <img :src="require('@/assets/images/avatar/grand_girl.png')"
            :class="[currentAvatarGroup == 'big' ? 'current_avatar' : 'avatar']">
         <p>{{$t('Big')}}</p>
        </div>

      </b-col>
    </b-row>
    <b-row>
      <b-col cols='12'>
        <div class="avatar-selector-container">
          <div 
            class="avatar-item" 
            @click="setAvatar(57)"
            >
              <img :src="require('@/assets/images/avatar/AvatarSprite_' + 57 + '.png?v4')"
                :class="[currentAvatar == 57 ? 'current_avatar' : 'avatar']">
          </div>
          <div v-for="index in first_range" :key="index"
            class="avatar-item" 
            @click="setAvatar(index)"
            >
              <img :src="require('@/assets/images/avatar/AvatarSprite_' + index + '.png?v4')"
                :class="[currentAvatar == index ? 'current_avatar' : 'avatar']">
          </div>
          <div v-for="index in second_range" :key="index"
            class="avatar-item" 
            @click="setAvatar(index)"
            >
              <img :src="require('@/assets/images/avatar/AvatarSprite_' + index + '.png?v4')"
                :class="[currentAvatar == index ? 'current_avatar' : 'avatar']">
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
   BRow, BCol, BButton, 
} from 'bootstrap-vue'
export default {
  components:{
    BRow, BCol, BButton
  },
  props: ['avatar_id', 'avatar_count'],
  data(){
    let small_range_1 = []
    for (let index = 1; index < 25; index++) {
      small_range_1.push(index)
    }
    let small_range_2 = []
    for (let index = 58; index < 81; index++) {
      small_range_2.push(index)
    }
    let big_range_1 = []
    for (let index = 25; index < 57; index++) {
      big_range_1.push(index)
    }
    let big_range_2 = []
    for (let index = 81; index <= 133; index++) {
      big_range_2.push(index)
    }
    
      return {
          small_range_1,
          small_range_2,
          big_range_1,
          big_range_2,
          avatars: [],
          currentAvatar: this.avatar_id,
          currentAvatarGroup: 'small'
      }
  },
  methods: {
    setAvatar(id){
      this.currentAvatar = id;
      this.$emit('set-avatar', id);
    }
  },
  computed:{
    first_range(){
      if(this.currentAvatarGroup=='small'){
        return this.small_range_1
      } else {
        return this.big_range_1
      }
    },
    second_range(){
      if(this.currentAvatarGroup=='small'){
        return this.small_range_2
      } else {
        return this.big_range_2
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.avatar-modal{
  background-color: rgb(245, 245, 245);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}
.avatar-modes-item{
    display: inline-block;
    margin: 0 10px;
    p{
      text-align: center;
    }
    img{
        max-width: 70px;
        max-height: 70px;
    }
    .current_avatar{
        border-radius: 50%;
        border: 5px solid #e27d02;
        background-color: orange;
        &:hover{
            background-color: #ff8c00;
            cursor: pointer;
        }
    }
    .avatar{
        /* background-color: #09b8dd;
        border: 5px solid #0193b1; */
        border-radius: 50%;
        &:hover{
            /* background-color: #02a3c4; */
            cursor: pointer;
        }
    }
}
.avatar-selector-container{
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
  gap: 10px;

  .avatar-item{
      flex-grow: 1;
      max-width: 100px;
      max-height: 100px;
      overflow: hidden;
      img{
          max-width: 100px;
          max-height: 100px;
      }
      .current_avatar{
          border-radius: 50%;
          border: 5px solid #e27d02;
          background-color: orange;
          &:hover{
              background-color: #ff8c00;
              cursor: pointer;
          }
      }
      .avatar{
          background-color: #09b8dd;
          border: 5px solid #0193b1;
          border-radius: 50%;
          &:hover{
              background-color: #02a3c4;
              cursor: pointer;
          }
      }
  }
}


</style>